import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AskData } from './ask-data.interface';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'eng-ask',
  templateUrl: './ask.component.html',
  styleUrls: ['./ask.component.scss'],
  providers: [CommonModule, MatButtonModule],
})
export class AskComponent {
  constructor(
    public dialogRef: MatDialogRef<AskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AskData,
  ) {}

  onClose(status: boolean): void {
    this.dialogRef.close(status);
  }

  getPathImg(path: string) {
    return 'assets/img/' + path;
  }

  get isFirefox() {
    // @ts-expect-error trick for check Firefox
    return typeof InstallTrigger !== 'undefined';
  }
}
