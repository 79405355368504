<div class="img">
  @if (data.img) {
    <img [src]="getPathImg(data.img)" alt="img" />
  }
</div>
<div class="popup" [class.isFirefox]="isFirefox">
  <div class="pseudo-img"></div>
  <h2 class="accent text-align-center title bold-5">{{ data.title | translate }}</h2>
  <h6 class="dark text-align-center subtitle bold-3" *ngIf="data.subtitle">
    {{ data.subtitle | translate }}
  </h6>
  <div class="dark text-align-center sure bold-3 h9" *ngIf="data.sure">
    {{ data.sure | translate }}
  </div>
  <div class="btn">
    <button mat-flat-button color="snow" class="h6 dark shadow" (click)="onClose(false)">
      {{ data.no | translate }}
    </button>
    <button mat-flat-button color="primary" class="h6 dark shadow" (click)="onClose(true)">
      {{ data.yes | translate }}
    </button>
  </div>
</div>
