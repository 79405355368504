import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AskComponent } from './ask/ask.component';
import { AskData } from './ask/ask-data.interface';
import { StatusComponent } from './status/status.component';
import { StatusData } from './status/status-data.interface';
import { ButtonSpinnerComponent } from '../form-controls/components/spinners/button-spinner.component';

@Injectable({
  providedIn: 'any',
})
export class PopupsService {
  constructor(
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) {}

  showBottomSheet(component: any): MatBottomSheetRef<object, object> {
    return this.bottomSheet.open(component, {
      backdropClass: 'eng-bottom-sheet',
      panelClass: 'eng-panel-bottom-sheet',
      autoFocus: false,
    });
  }

  closeBottomSheet(): void {
    this.bottomSheet._openedBottomSheetRef?.dismiss();
  }

  closeAllDialog(): void {
    this.dialog.closeAll();
  }

  showAsk(data: AskData, disableClose?: boolean): MatDialogRef<any, any> {
    return this.show(AskComponent, data, disableClose);
  }
  showSpinner(): MatDialogRef<any, any> {
    return this.show(ButtonSpinnerComponent, {});
  }

  showStatus(data: StatusData, disableClose: boolean = false): MatDialogRef<any, any> {
    if (!data.translateParam) {
      data.translateParam = {};
    }
    if (!data.titleClass) {
      data.titleClass = '';
    }
    return this.show(StatusComponent, data, disableClose);
  }

  showDialog(
    component: any,
    extra: {
      data?: object;
      panelClasses?: string;
      backdropClass?: string;
      disableClose?: boolean;
    } = {
      data: {},
      panelClasses: '',
      backdropClass: '',
      disableClose: false,
    },
  ): MatDialogRef<any, any> {
    return this.dialog.open(component, {
      backdropClass: ['backdrop', ...(extra?.backdropClass ?? '').split(' ')],
      panelClass: ['panel', ...(extra?.panelClasses ?? '').split(' ')],
      disableClose: extra?.disableClose ?? false,
      data: extra.data,
      autoFocus: false,
    });
  }

  private show(
    component: any,
    data: object,
    disableClose: boolean = false,
  ): MatDialogRef<any, any> {
    return this.dialog.open(component, {
      backdropClass: 'backdrop',
      panelClass: 'panel',
      autoFocus: false,
      disableClose,
      data,
    });
  }
}
